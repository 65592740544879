import { Button } from '@/components/ui/button';
import React from 'react';
import { Link } from 'react-router-dom';

const Page500 = () => {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-destructive dark:text-destructive">
            500
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
            Something went wrong.
          </p>
          <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
            Sorry, there was an error on our side. Please try again later or go
            back to the home page.
          </p>
          <Button asChild>
            <Link to="/">Go to home page</Link>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Page500;
