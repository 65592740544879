import React, { useMemo } from 'react'

import DropdownPid, { usePid } from 'components/DropdownPid'
import useSWR from 'swr'

import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { createColumnVisibilityStore } from '@/components/zthd/data-table'
import { DataTableViewOptions } from '@/components/zthd/data-table-view-options'
import { useColumnFilters } from 'components/data-table-selectors'
import {
  costColumn,
  countryColumn,
  cpiColumn,
  installColumn,
  profitColumn,
  revenueColumn,
} from './columns'

const useColumnVisibilityStore = createColumnVisibilityStore({}, 'weeklydoc')

const WeeklyDoc = () => {
  const pid = usePid((state) => state.value)
  const { columnVisibility, setColumnVisibility } = useColumnVisibilityStore()
  const columnFilters = useColumnFilters((state) => state.columnFilters)

  const { data: revenue } = useSWR(['/revenues/reports', { pid, ws: 5 }])

  const { data: cost } = useSWR(['/costs/reports', { pid, ws: 5 }])

  const sortCountries = useMemo(() => {
    const c1 = Object.keys(revenue?.thisWeek || {})
    const c2 = Object.keys(cost?.thisWeek || {})
    const c3 = Array.from(new Set([...c1, ...c2]))

    const countries = c3.filter((id) => id !== 'weekId' && id !== 'weekDays')
    let sortCountries
    if (revenue) {
      sortCountries = countries.sort(
        (a, b) => revenue?.thisWeek?.[b] - revenue?.lastWeek?.[a],
      )
    } else if (cost) {
      sortCountries = countries.sort(
        (a, b) => cost?.thisWeek?.[b]?.costs - cost?.lastWeek?.[a]?.costs,
      )
    } else {
      sortCountries = countries
    }
    return sortCountries
  }, [revenue, cost])

  const data = useMemo(() => {
    return sortCountries?.map((country) => ({
      country,
      thisWeekRevenues: revenue?.thisWeek?.[country],
      lastWeekRevenues: revenue?.lastWeek?.[country],
      thisWeekCosts: cost?.thisWeek?.[country],
      lastWeekCosts: cost?.lastWeek?.[country],
    }))
  }, [sortCountries, revenue, cost])

  const columns = useMemo(() => {
    const rows = [countryColumn]
    rows.push(revenueColumn)
    rows.push(costColumn)
    rows.push(profitColumn)
    rows.push(installColumn)
    rows.push(cpiColumn)

    return rows
  }, [])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    enableMultiRowSelection: true,
    state: { columnFilters, columnVisibility },
  })

  const thisWeek = (revenue ?? cost)?.thisWeek
  const lastWeek = (revenue ?? cost)?.lastWeek

  return (
    <>
      <div className="flex items-center gap-4 py-4 flex-wrap sm:gap-2 sm:py-2">
        <DropdownPid showLabel={false} />
        <div>
          this week:
          <span className="text-primary">
            {thisWeek?.weekDays?.[0]}~
            {thisWeek?.weekDays?.[thisWeek?.weekDays?.length - 1]}
          </span>
        </div>
        <div>
          last week:
          <span className="text-primary">
            {lastWeek?.weekDays?.[0]}~
            {lastWeek?.weekDays?.[lastWeek?.weekDays?.length - 1]}
          </span>
        </div>
        <div> daily avg, not week sum.</div>
        <DataTableViewOptions table={table} />
      </div>
      <DataTableWithBorder table={table} />
    </>
  )
}

export default WeeklyDoc
