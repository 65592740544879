import { Products } from 'comm'
import { useDatePicker } from 'components/MyDatePicker'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { formatPercent, formatUSD, getDateRange } from 'utils'

import { Button } from '@/components/ui/button'
import { createColumnVisibilityStore } from '@/components/zthd/data-table'
import { DataTableViewOptions } from '@/components/zthd/data-table-view-options'
import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  useColumnFilters,
  VSWeekSelector,
} from 'components/data-table-selectors'
import { useCountry } from 'components/DropdownCountry'
import useSWR from 'swr'
import Td2 from '../Home/Td2'
import { columnDate } from './columns'

const useColumnVisibilityStore = createColumnVisibilityStore({}, 'pcp')

const pidColumn = (code, name) => ({
  id: `${code}-${name}`,
  header: `${code}-${name}`,
  accessorFn: (row) => {
    const costs = row.costs?.[code]?.costs
    const revenues = row.revenues?.[code]
    const profit = revenues - costs

    const costsTotal = row.costs?.total?.costs
    const revenuesTotal = row.revenues?.total

    const profitTotal = revenuesTotal - costsTotal

    return { curr: profit, total: profitTotal }
  },
  cell: (row) => {
    const { curr, total } = row.getValue()
    const formatted = formatUSD(curr, 0, 0)
    const per = formatPercent(curr / total)
    return <Td2 line2={per}>{formatted}</Td2>
  },
})

const Home = () => {
  const startDate = useDatePicker((state) => state.startDate)
  const endDate = useDatePicker((state) => state.endDate)
  const country = useCountry((state) => state.value)

  const { columnVisibility, setColumnVisibility } = useColumnVisibilityStore()
  const columnFilters = useColumnFilters((state) => state.columnFilters)

  const { data: revenues, mutateR } = useSWR([
    '/revenues/pc',
    { startDate, endDate, country },
  ])

  const { data: costs, mutateC } = useSWR([
    '/costs/pc',
    { startDate, endDate, country },
  ])

  const data = useMemo(() => {
    const dateRange = getDateRange(dayjs(startDate), dayjs(endDate))
    return dateRange.map((date) => ({
      date,
      costs: costs?.[date],
      revenues: revenues?.[date],
    }))
  }, [startDate, endDate, costs, revenues])

  const columns = useMemo(() => {
    const ps = Products.filter(({ code }) => !['22', '23'].includes(code))
    const rows = []
    rows.push(columnDate)
    ps.forEach((p) => {
      const { code, name } = p
      rows.push(pidColumn(code, name))
    })
    return rows
  }, [])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    enableMultiRowSelection: true,
    state: {
      columnFilters,
      columnVisibility,
    },
  })

  const refresh = () => {
    mutateR()
    mutateC()
  }

  return (
    <>
      <div className="flex items-center gap-1 py-4">
        <VSWeekSelector />
        <Button onClick={refresh}>refresh</Button>
        <DataTableViewOptions table={table} />
      </div>
      <DataTableWithBorder table={table} />
    </>
  )
}

export default Home
