import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function fuzzyFilter(filterValue, value) {
  const searchWords = filterValue.toLowerCase().split(' ');
  const itemValue = value.toLowerCase();

  return searchWords.every((word) => itemValue.includes(word));
}
