import React from 'react'
import { cn } from '@/lib/utils'
import { UpdateIcon } from '@radix-ui/react-icons'

const Loader = React.forwardRef(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn('flex items-center')} {...props}>
    <UpdateIcon
      className={cn('h-6 w-6 text-primary animate-spin mr-2', className)}
    />
    {children}
  </div>
))
Loader.displayName = 'Loader'

export default Loader
