import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { Countries, Products } from 'comm'
import { postFetcher } from 'hooks'
import { useRole } from 'hooks/auth'
import useSWR, { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'
import { create } from 'zustand'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { countryColumn, pidColumn } from './columns'

const countryCodes = [
  'total',
  'BR',
  'US',
  'JP',
  'MX',
  'DE',
  'HK',
  'IT',
  'SA',
  'KR',
  'TW',
  'ES',
  'ZA',
  'ID',
  'GB',
  'FR',
  'TH',
  'AR',
  'CO',
  'SG',
  'MY',
  'CA',
] // 指定需要筛选的国家代码数组

const Index = () => {
  const { data: data1 = {} } = useSWR(['/ers/baselines'])
  const { data: vss = {} } = useSWR(['/ers/vs'])

  const { enable, toggleEnable, showEditor } = useErStore()
  const isAdmin = useRole('admin')

  const data = useMemo(() => {
    const countrys = Countries.filter((c) => countryCodes.includes(c.code))
    return countrys.map((country) => ({
      country,
      baselines: data1?.[country.code],
      vs: vss?.[country.code],
    }))
  }, [data1, vss])

  const columns = useMemo(() => {
    const rows = [countryColumn]
    rows.push(...Products.map((p) => pidColumn(p)))
    return rows
  }, [])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableMultiRowSelection: true,
  })

  const onCellClick = (cell) => {
    // 获取单元格的值
    const cellValue = cell.getValue()
    // 获取行数据
    const original = cell.row.original

    if (isAdmin && enable) {
      showEditor({
        pid: cellValue.pid,
        country: original.country.code,
        before: cellValue.er,
      })
    }
  }

  return (
    <>
      <EditorDialog />
      <div className="flex items-center gap-4 py-4 sm:gap-2 sm:py-2">
        {isAdmin && (
          <>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="enable-editor"
                checked={enable}
                onCheckedChange={toggleEnable}
              />
              <label
                htmlFor="terms"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Enable editor model
              </label>
            </div>
          </>
        )}
      </div>
      <DataTableWithBorder table={table} onCellClick={onCellClick} />
    </>
  )
}

export const useErStore = create((set) => ({
  enable: false,
  show: false,
  pid: '',
  country: '',
  before: 0,
  toggleEnable: () => set((state) => ({ enable: !state.enable })),
  showEditor: ({ pid, country, before }) =>
    set({ pid, country, before, show: true }),
  hideEditor: () => set({ show: false }),
}))

const FormSchema = z.object({
  er: z.preprocess(
    (val) => Number(val),
    z.number().min(0, {
      message: 'er must be >= 0',
    }),
  ),
})

const EditorDialog = () => {
  const { pid, country, before } = useErStore()

  const show = useErStore((state) => state.show)
  const { hideEditor } = useErStore()

  const { trigger } = useSWRMutation('/ers/baselines', postFetcher)
  const onSubmit = (data) => {
    trigger(
      { pid, country, value: data.er },
      {
        onSuccess: () => {
          hideEditor()
          mutate(['/ers/baselines'])
        },
      },
    )
  }

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      er: before !== undefined ? before : 0, // 使用 before 初始化 er
    },
  })

  const {
    formState: { isValid, isSubmitting },
    setValue,
  } = form

  useEffect(() => {
    setValue('er', before !== undefined ? before : 0)
  }, [before, setValue])

  return (
    <Dialog open={show} onOpenChange={hideEditor}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Editor er.</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <span>
              Pid:<span className="text-destructive">{pid}</span>
            </span>
            <span>
              Country:<span className="text-destructive">{country}</span>
            </span>
            <span>
              Before:<span className="text-destructive">{before}</span>
            </span>
          </div>
          <div className="grid gap-4 py-4">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-6"
              >
                <FormField
                  control={form.control}
                  name="er"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          type="number"
                          placeholder=""
                          {...field}
                          step="0.1"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <DialogClose asChild>
                  <Button disabled={!isValid || isSubmitting} type="submit">
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </Button>
                </DialogClose>
              </form>
            </Form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default Index
