import { create } from 'zustand'
import Selector from './ui/Selector'

export const useColumnFilters = create((set) => ({
  columnFilters: [],
  update: (value) =>
    set({
      columnFilters: value === -1 ? [] : [{ id: 'date', value: value }],
    }),
}))

const WSS = [
  { code: -1, name: 'Select VS Week' },
  { code: 1, name: 'VS 周一' },
  { code: 2, name: 'VS 周二' },
  { code: 3, name: 'VS 周三' },
  { code: 4, name: 'VS 周四' },
  { code: 5, name: 'VS 周五' },
  { code: 6, name: 'VS 周六' },
  { code: 0, name: 'VS 周日' },
]

export const VSWeekSelector = () => {
  const value = useColumnFilters((state) => state.columnFilters?.[0]?.value)
  const update = useColumnFilters((state) => state.update)
  return (
    <Selector
      datas={WSS}
      showLabel
      labelName="VS Week"
      value={value}
      update={update}
    />
  )
}
