import React from 'react'
import { Checkbox } from '@/components/ui/checkbox'
import { cn } from '@/lib/utils'

const MyCheckbox = React.forwardRef(({ className, label, ...props }, ref) => (
  <div ref={ref} className={cn('flex items-center space-x-2', className)}>
    <Checkbox id="enable-editor" {...props} />
    <label
      htmlFor="terms"
      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
    >
      {label}
    </label>
  </div>
))

MyCheckbox.displayName = 'MyCheckbox'

export default MyCheckbox
