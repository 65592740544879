import * as React from 'react'

import { Button } from '@/components/ui/button'
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/ui/drawer'
import { useMediaQuery } from '@/hooks/use-media-query'
import { cn } from '@/lib/utils'
import { LineChart as LineChartIcon } from 'lucide-react'

export const ChartCodeViewer = ({ className, children }) => {
  const isDesktop = useMediaQuery('(min-width: 768px)')

  const button = (
    <Button variant="outline">
      <LineChartIcon className="w-4 h-4" />
      Chart
    </Button>
  )

  const content = (
    <div className="chart-wrapper hidden sm:block [&>div]:rounded-none [&>div]:border-0 [&>div]:border-b [&>div]:shadow-none [&_[data-chart]]:mx-auto [&_[data-chart]]:max-h-[35vh]">
      {children}
    </div>
  )

  // if (isDesktop) {
  //   return (
  //     <Sheet>
  //       <SheetTrigger asChild>{button}</SheetTrigger>
  //       <SheetContent
  //         side="right"
  //         className={cn(
  //           'flex flex-col gap-0 border-l-0 p-0 dark:border-l sm:max-w-sm md:w-[700px] md:max-w-[700px]',
  //           className,
  //         )}
  //       >
  //         {content}
  //       </SheetContent>
  //     </Sheet>
  //   )
  // }

  return (
    <Drawer>
      <DrawerTrigger asChild>{button}</DrawerTrigger>
      <DrawerContent
        className={cn(
          'flex max-h-[80vh] flex-col sm:max-h-[90vh] [&>div.bg-muted]:shrink-0',
          className,
        )}
      >
        <DrawerHeader className="hidden">
          <DrawerTitle>Roi Chart</DrawerTitle>
          <DrawerDescription>some chart</DrawerDescription>
        </DrawerHeader>
        <div className="flex h-full flex-col overflow-auto">{content}</div>
      </DrawerContent>
    </Drawer>
  )
}
