import { BookmarkFilledIcon, BookmarkIcon } from '@radix-ui/react-icons'
import { useDatePicker } from 'components/MyDatePicker'
import dayjs from 'dayjs'
import { useReadableCost, useReadableEr, useReadableRevenue } from 'hooks/auth'
import React, { useMemo } from 'react'
import useSWR from 'swr'
import { getDateRange } from 'utils'

import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { Button } from '@/components/ui/button'
import MyLink from '@/components/zthd/MyLink'

import { getWeek } from 'hooks/tools'
import {
  costColumn,
  costsDetailColumn,
  cpiColumn,
  cpnColumn,
  dauColumn,
  dnuColumn,
  erColumn,
  installsColumn,
  ltvColumn,
  originalColumn,
  profitColumn,
  revenueColumn,
  revenueDetailColumn,
  rpaColumn,
  rpnColumn,
} from './columns'

import { useFetchNotes, useNoteStore } from '../NotesDrawer'

import { ChartCodeViewer } from '@/components/zthd/chart-code-viewer'
import { createColumnVisibilityStore } from '@/components/zthd/data-table'
import { DataTableViewOptions } from '@/components/zthd/data-table-view-options'
import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import Loader from '@/components/zthd/Loader'
import {
  useColumnFilters,
  VSWeekSelector,
} from 'components/data-table-selectors'
import { useCountry } from 'components/DropdownCountry'
import { usePid2 } from 'components/DropdownPid'
import { mutateRequest } from 'hooks'
import { usePackageName } from './DropdownPackage'
import RoiChart from './roi-chart'
import Td2 from './Td2'

const useColumnVisibilityStore = createColumnVisibilityStore({}, 'roi')

const DataTable = () => {
  const pids = usePid2((state) => state.pids)
  const pid = pids.join(',') === '' ? 'total' : pids.join(',')
  const country = useCountry((state) => state.value)
  const packageName = usePackageName((state) => state.value)

  const readableRevenue = useReadableRevenue(pid, country)
  const readableCost = useReadableCost(pid, country)
  const readableEr = useReadableEr(pid, country)
  const { data: baselins } = useSWR(['/ers/baselines'])
  const erBaseLine = baselins?.[country]?.[pid] ?? 0.35

  const startDate = useDatePicker((state) => state.startDate)
  const endDate = useDatePicker((state) => state.endDate)

  const queryOpt = useMemo(
    () => ({ startDate, endDate, pid: pids, country, packageName }),
    [startDate, endDate, pids, country, packageName],
  )

  const { data: revenues, isValidating: isFetchRevenueing } = useSWR([
    '/revenues',
    queryOpt,
  ])

  const { data: costs, isValidating: isFetchCosting } = useSWR([
    '/costs',
    queryOpt,
  ])

  const { data: dnus, isValidating: isFetchDnuing } = useSWR([
    '/userActives',
    queryOpt,
  ])

  const { data: ers, isValidating: isFetchErsing } = useSWR([
    readableEr ? '/ers' : null,
    queryOpt,
  ])

  const notes = useFetchNotes()

  const handleRefrech = () => {
    mutateRequest('/revenues')
    mutateRequest('/costs')
    mutateRequest('/userActives')
    mutateRequest('/ers')
    mutateRequest('/notes')
  }
  const columnVisibility = useColumnVisibilityStore(
    (state) => state.columnVisibility,
  )
  const setColumnVisibility = useColumnVisibilityStore(
    (state) => state.setColumnVisibility,
  )

  const columnFilters = useColumnFilters((state) => state.columnFilters)

  const data = useMemo(() => {
    const dateRange = getDateRange(dayjs(startDate), dayjs(endDate))
    return dateRange.map((date) => {
      const _revenues = revenues?.[date]
      const revenue = _revenues?.total
      const _costs = costs?.[date]
      const cost = _costs?.total?.costs
      const installs = _costs?.total?.installs
      const ua = dnus?.[date]
      return {
        date,
        revenues: _revenues,
        revenue,
        costs: _costs,
        cost,
        profit: revenue - cost,
        installs,
        dnu: ua?.dnu,
        dau: ua?.dau,
        original: ua?.dnu - installs,
        er: ers?.[date],
        notes: notes?.filter((n) => n.date === date),
      }
    })
  }, [startDate, endDate, revenues, costs, dnus, ers, notes])

  const showNotes = useNoteStore((state) => state.showNotes)

  const columns = useMemo(() => {
    const rows = [columnDate(pid, showNotes), dnuColumn, dauColumn]

    if (readableCost) {
      rows.push(originalColumn)
      rows.push(installsColumn)
    }

    if (readableRevenue) {
      rows.push(revenueColumn)
    }
    if (readableCost) {
      rows.push(costColumn)
    }

    if (readableRevenue && readableCost) {
      rows.push(profitColumn)
    }

    if (readableEr) {
      rows.push(erColumn(erBaseLine))
    }
    if (readableCost) {
      rows.push(cpnColumn)
      rows.push(cpiColumn)
    }

    if (readableRevenue) {
      rows.push(rpnColumn)
      rows.push(rpaColumn)
    }

    if (readableRevenue && readableCost) {
      rows.push(ltvColumn)
    }

    if (readableCost) {
      rows.push({ id: 'space-end', isPlaceholder: true, enableHiding: false })
      rows.push(costsDetailColumn)
    }
    if (readableRevenue) {
      rows.push({ id: 'space-end2', isPlaceholder: true, enableHiding: false })
      rows.push(revenueDetailColumn)
    }
    return rows
  }, [erBaseLine, pid, readableRevenue, readableCost, readableEr, showNotes])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    enableMultiRowSelection: true,
    state: {
      columnFilters,
      columnVisibility,
    },
  })

  return (
    <>
      <div className="flex items-center gap-4 py-4 flex-wrap sm:gap-2 sm:py-2 xs:py-1 xs:gap-1">
        <VSWeekSelector />
        <Button onClick={handleRefrech} variant="outline">
          refresh
        </Button>
        <ChartCodeViewer>
          <RoiChart data={data} />
        </ChartCodeViewer>
        {(isFetchRevenueing ||
          isFetchCosting ||
          isFetchDnuing ||
          isFetchErsing) && <Loader>Loading...</Loader>}
        <div className="text-destructive text-nowrap xs:text-xs xs:text-wrap lg:text-base">
          er base line 值只在具体的产品下是准确的; 多个pid,或者全选时,等于35%.
        </div>
        <DataTableViewOptions table={table} />
      </div>
      <DataTableWithBorder table={table} />
    </>
  )
}

const columnDate = (pid, showNotes) => ({
  id: 'date',
  accessorFn: (row) => {
    const date = row.date
    const hasNotes = row.notes?.length > 0
    return { date, hasNotes }
  },
  header: 'Date',
  cell: (row) => {
    const meta = row.getValue()
    const date = meta.date
    const hasNotes = meta.hasNotes
    const [, week] = getWeek(date)
    return (
      <Td2
        line2={
          <div className="flex gap-1 justify-end">
            {hasNotes ? (
              <BookmarkFilledIcon
                className="text-primary"
                onClick={() => showNotes({ date })}
              />
            ) : (
              <BookmarkIcon onClick={() => showNotes({ date })} />
            )}
            (周{week})
          </div>
        }
      >
        <MyLink to={`cc/${pid}/${date}`}>{date}</MyLink>
      </Td2>
    )
  },
  enableHiding: false,
  filterFn: (row, columnId, filterValue) => {
    const date = row.getValue(columnId).date
    const [weekIndex] = getWeek(date)
    return weekIndex === filterValue
  },
})

export default DataTable
