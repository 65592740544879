import React from 'react'
import { createRoot } from 'react-dom/client'

import { fetcher } from 'hooks'
import { SWRConfig } from 'swr'
import './globals.css' // 引入全局CSS

import App from './App'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <SWRConfig
    value={{
      fetcher: fetcher,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      dedupingInterval: 60000,
    }}
  >
    <App />
  </SWRConfig>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
