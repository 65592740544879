import {
  DropdownCountry1,
  createCountryStore,
} from 'components/DropdownCountry'
import MyDatePicker, { useDatePicker } from 'components/MyDatePicker'
import React, { useMemo } from 'react'
import useSWR from 'swr'
import DeviceDropdown, { createDeviceStore } from './DropdownDevice'
import SkuDropdown, { createSkuIdStore } from './DropdownSku'

import { createColumnVisibilityStore } from '@/components/zthd/data-table'
import { DataTableViewOptions } from '@/components/zthd/data-table-view-options'
import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useColumnFilters } from 'components/data-table-selectors'
import {
  cancelColumn,
  dnuColumn,
  freeTryColumn,
  paidColumn,
  refundColumn,
  renewColumn,
  subAtColumn,
} from './columns'

const CACHE_KEY = 'event2'

const useColumnVisibilityStore = createColumnVisibilityStore({}, CACHE_KEY)
const useSkuId = createSkuIdStore('1634387596', CACHE_KEY)
const useCountry = createCountryStore('US', CACHE_KEY)
const useDevice = createDeviceStore('iPhone', CACHE_KEY)

const Event2 = () => {
  const skuId = useSkuId((state) => state.value)
  const updateSkuId = useSkuId((state) => state.update)
  const country = useCountry((state) => state.value)
  const updateCountry = useCountry((state) => state.update)

  const { startDate, endDate } = useDatePicker((state) => ({
    startDate: state.startDate,
    endDate: state.endDate,
  }))

  const device = useDevice((state) => state.value)
  const updateDevice = useDevice((state) => state.update)

  const { columnVisibility, setColumnVisibility } = useColumnVisibilityStore()
  const { columnFilters } = useColumnFilters()

  const { data: events } = useSWR([
    '/apple/events2',
    { pid: 12, country, startDate, endDate, skuId },
  ])
  const { data: dnus } = useSWR([
    '/apple/dnus',
    { pid: 12, country, startDate, endDate },
  ])

  const data = useMemo(() => {
    if (!events) return []
    const events1 = events.filter((e) => e[device] > 0)
    const uniqueAts = [...new Set(events1.map((item) => item.subAt))]
    return uniqueAts.map((subAt) => {
      const subAtEvents = events1
        .filter((item) => item.subAt === subAt)
        .map(({ event, [device]: value }) => ({
          event,
          value,
        }))

      const freeTry =
        events1.find(
          (e) => e.event === 'Start Introductory Offer' && e.subAt === subAt,
        )?.[device] || 0

      return {
        subAt,
        events: subAtEvents,
        freeTry,
        dnu: dnus?.find((item) => item.date === subAt)?.[device],
      }
    })
  }, [events, dnus, device])

  const columns = useMemo(() => {
    const rows = []
    rows.push(subAtColumn)
    rows.push(dnuColumn)
    rows.push(freeTryColumn)
    rows.push(paidColumn)
    rows.push(renewColumn)
    rows.push(refundColumn)
    rows.push(cancelColumn)
    // rows.push(billRetryColumn)
    // rows.push(reactivateColumn)
    // rows.push(gracePeriodColumn)
    return rows
  }, [])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    enableMultiRowSelection: true,
    state: {
      columnFilters,
      columnVisibility,
    },
  })

  return (
    <>
      <div className="flex items-center gap-4 py-4 sm:gap-2 sm:py-2 flex-wrap">
        <DropdownCountry1 value={country} update={updateCountry} />
        <MyDatePicker />
        <SkuDropdown value={skuId} update={updateSkuId} />
        <DeviceDropdown value={device} update={updateDevice} />
        <DataTableViewOptions table={table} />
      </div>
      <DataTableWithBorder table={table} />
    </>
  )
}

export default Event2
