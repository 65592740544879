import Tabs from 'components/Tabs'
import { useAuthStore } from 'hooks'
import React from 'react'
import { Outlet } from 'react-router-dom'

const TabItems = [
  // { name: 'Subs', to: '', end: true },
  { name: 'Events (base eventAt)', to: 'events1' },
  { name: 'Events (base subAt)', to: 'events2' },
  { name: 'What happens after sub', to: 'events3' },
  { name: 'Sub At Timeline', to: 'subat-timeline' },
]

function Index() {
  const user = useAuthStore((state) => state.auth?.user)
  const uids = ['549g3eae', 'bd4376ce', '2426ca7f', '972c2g2c']
  if (uids.includes(user.id)) {
    return (
      <div className="flex flex-col">
        <Tabs datas={TabItems} />
        <Outlet />
      </div>
    )
  }
  return <div>no permision</div>
}

export default Index
