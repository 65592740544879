import { BookmarkFilledIcon, BookmarkIcon } from '@radix-ui/react-icons'
import { useDatePicker } from 'components/MyDatePicker'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import useSWR from 'swr'
import { getDateRange } from 'utils'

import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { Button } from '@/components/ui/button'
import MyLink from '@/components/zthd/MyLink'

import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import { getWeek } from 'hooks/tools'

import Loader from '@/components/zthd/Loader'
import { createColumnVisibilityStore } from '@/components/zthd/data-table'
import { DataTableViewOptions } from '@/components/zthd/data-table-view-options'
import {
  useColumnFilters,
  VSWeekSelector,
} from 'components/data-table-selectors'
import { mutateRequest } from 'hooks'
import Td2 from './Td2'
import { ColumnCost, ColumnIAA, ColumnIAP } from './data-table-columns'
import { useCountry } from './index'

const useColumnVisibilityStore = createColumnVisibilityStore({}, 'roi')

const DataTable = () => {
  const pid = '12'
  const country = useCountry((state) => state.value)

  const startDate = useDatePicker((state) => state.startDate)
  const endDate = useDatePicker((state) => state.endDate)

  const queryOpt = useMemo(
    () => ({ startDate, endDate, pid: ['12'], country }),
    [startDate, endDate, country],
  )

  const {
    data: revenues,
    isValidating: isFetchRevenueing,
    mutate: mutateR,
  } = useSWR(['/revenues', queryOpt])

  const {
    data: costs,
    isValidating: isFetchCosting,
    mutate: mutateC,
  } = useSWR(['/costs', queryOpt])

  const handleRefrech = () => {
    mutateR()
    mutateC()
    mutateRequest('/revenues/reg/iap')
  }
  const { columnVisibility, setColumnVisibility } = useColumnVisibilityStore()
  const columnFilters = useColumnFilters((state) => state.columnFilters)

  const data = useMemo(() => {
    const dateRange = getDateRange(dayjs(startDate), dayjs(endDate))
    return dateRange.map((date) => ({
      date,
      revenues: revenues?.[date],
      revenue: revenues?.[date]?.total,
      costs: costs?.[date],
      cost: costs?.[date]?.total?.costs,
      installs: costs?.[date]?.total?.installs,
      country,
    }))
  }, [startDate, endDate, revenues, costs, country])

  const columns = useMemo(() => {
    const rows = [columnDate(pid, false)]
    rows.push(ColumnCost)
    rows.push(ColumnIAA)
    rows.push(ColumnIAP)

    return rows
  }, [pid])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    enableMultiRowSelection: true,
    state: {
      columnFilters,
      columnVisibility,
    },
  })

  return (
    <>
      <div className="flex items-center gap-4 py-4 flex-wrap sm:gap-2 sm:py-2 xs:py-1 xs:gap-1">
        <VSWeekSelector />
        <Button onClick={handleRefrech} variant="outline">
          refresh
        </Button>
        {(isFetchRevenueing || isFetchCosting) && <Loader>Loading...</Loader>}
        <div className="text-destructive text-nowrap xs:text-xs xs:text-wrap lg:text-base">
          当天的Cost，当天的IAA，累积的IAP，有一个点:用户是历史累积的,
          country支持US,JP,GB,CA
        </div>
        <DataTableViewOptions table={table} />
      </div>
      <DataTableWithBorder table={table} />
    </>
  )
}

const columnDate = (pid, showNotes) => ({
  id: 'date',
  accessorFn: (row) => {
    const date = row.date
    const hasNotes = row.notes?.length > 0
    return { date, hasNotes }
  },
  header: 'Date',
  cell: (row) => {
    const meta = row.getValue()
    const date = meta.date
    const hasNotes = meta.hasNotes
    const [, week] = getWeek(date)
    return (
      <Td2
        line2={
          <div className="flex gap-1 justify-end">
            {hasNotes ? (
              <BookmarkFilledIcon
                className="text-primary"
                onClick={() => showNotes({ date })}
              />
            ) : (
              <BookmarkIcon onClick={() => showNotes({ date })} />
            )}
            (周{week})
          </div>
        }
      >
        <MyLink to={`cc/${pid}/${date}`}>{date}</MyLink>
      </Td2>
    )
  },
  enableHiding: false,
  filterFn: (row, columnId, filterValue) => {
    const date = row.getValue(columnId).date
    const [weekIndex] = getWeek(date)
    return weekIndex === filterValue
  },
})

export default DataTable
