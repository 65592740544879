import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { flexRender } from '@tanstack/react-table'
import React, { useMemo } from 'react'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

const MyDataTable = ({ table }) => {
  const visibleLength = useMemo(
    () =>
      table?.getAllLeafColumns()?.filter((column) => column?.getIsVisible())
        ?.length,
    [table],
  )

  return (
    <Table>
      <TableHeader>
        {table?.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              )
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table?.getRowModel()?.rows?.length ? (
          table
            .getRowModel()
            .rows.map((row) => (
              <CustomTableRow
                key={row.id}
                row={row}
                visibleLength={visibleLength}
              />
            ))
        ) : (
          <TableRow>
            <TableCell colSpan={visibleLength} className="h-24 text-center">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

const CustomTableRow = ({ row, visibleLength }) => {
  if (row?.original?.isEmptyRow) {
    return (
      <TableRow className="h-8">
        <TableCell colSpan={visibleLength}></TableCell>
      </TableRow>
    )
  }

  return (
    <TableRow className="data-[state=selected]:border-primary">
      {row?.getVisibleCells()?.map((cell) => (
        <TableCell key={cell.id}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableCell>
      ))}
    </TableRow>
  )
}

export const createColumnVisibilityStore = (init = {}, key = '') =>
  create(
    persist(
      (set) => ({
        columnVisibility: init, // 初始化列可见性状态
        setColumnVisibility: (updateFn) =>
          set((state) => ({
            columnVisibility: updateFn(state.columnVisibility),
          })),
      }),
      {
        name: `column-visibility-storage-${key}`, // 存储的名称，包含唯一键
        storage: createJSONStorage(() => localStorage), // 使用localStorage来持久化
      },
    ),
  )

export const createTableStore = (
  init = {
    columnSelection: {},
  },
  key = '',
) =>
  create(
    persist(
      (set) => ({
        ...init,
        setColumnSelection: (updateFn) =>
          set((state) => ({
            columnSelection: updateFn(state.columnSelection),
          })),
      }),
      {
        name: `table-storage-${key}`,
        storage: createJSONStorage(() => localStorage),
      },
    ),
  )

export default MyDataTable
