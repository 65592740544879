import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import weekday from 'dayjs/plugin/weekday'

dayjs.extend(customParseFormat)
dayjs.extend(weekday)

export const getWeek = (data) => {
  const index = data ? dayjs(`${data}`, 'YYYYMMDD').day() : 8
  const mappings = ['日', '一', '二', '三', '四', '五', '六', '？？']
  return [index, mappings[index]]
}
