import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { cn } from '@/lib/utils'
import { flexRender } from '@tanstack/react-table'
import React, { useMemo } from 'react'

const DataTableWithBorder = ({
  table,
  onHeadClick,
  onRowClick,
  onCellClick,
}) => {
  const visibleLength = useMemo(
    () =>
      table?.getAllLeafColumns().filter((column) => column.getIsVisible())
        ?.length,
    [table],
  )

  return (
    <Table className="border-collapse border border-slate-400 w-auto">
      <TableHeader className="border-b-black border-b-2">
        {table?.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead
                  key={header.id}
                  colSpan={header.colSpan}
                  onClick={() => onHeadClick && onHeadClick(header)}
                  className="border border-muted text-bold bg-muted"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              )
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table?.getRowModel()?.rows?.length ? (
          table.getRowModel().rows.map((row, i) => (
            <TableRow
              key={row.id}
              className={cn(
                `data-[state=selected]:bg-yellow-100 hover:bg-yellow-100`,
                i % 2 === 0 ? 'bg-gray-200' : 'bg-white',
              )}
              data-state={row.getIsSelected() && 'selected'}
              onClick={() => row.toggleSelected()}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  className="border border-slate-300 text-base"
                  onClick={() => onCellClick && onCellClick(cell)}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={visibleLength} className="h-24 text-center ">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

export default DataTableWithBorder
