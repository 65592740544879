import Tabs from 'components/Tabs'
import React from 'react'
import { Outlet } from 'react-router-dom'

import NotesDrawer from './NotesDrawer'

const TabItems = [
  { name: 'Home', to: '', end: true },
  { name: 'Product contrast', to: 'pc' },
  { name: 'Weekly', to: 'weekly' },
  { name: 'Weekly doc', to: 'weekdoc' },
]

function Index() {
  return (
    <div className="flex flex-col">
      <Tabs datas={TabItems} />
      <Outlet />
      <NotesDrawer />
    </div>
  )
}

export default Index
