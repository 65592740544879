import React, { useMemo, useState } from 'react'
import {
  CartesianGrid,
  LabelList,
  Line,
  LineChart,
  XAxis,
  YAxis,
} from 'recharts'

import { Card, CardContent } from '@/components/ui/card'
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'
import { Checkbox } from '@/components/ui/checkbox'
import MyCheckbox from '@/components/zthd/MyCheckbox'
import { formatUSD } from 'utils'

export const description = 'A line chart'

const chartConfig = {
  profit: {
    label: 'Profit',
    color: 'hsl(var(--chart-3))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'left',
  },
  er: {
    label: 'ER',
    color: 'hsl(var(--chart-4))',
    formatter: (value) => `${value}%`,
    yAxis: 'right',
  },
  dau: {
    label: 'DAU',
    color: 'hsl(var(--chart-4))',
    formatter: (value) => (value / 10000).toFixed(2) + 'W',
    yAxis: 'right',
  },
  dnu: {
    label: 'DNU',
    color: 'hsl(var(--chart-5))',
    formatter: (value) => (value / 10000).toFixed(2) + 'W',
    yAxis: 'right',
  },
  installs: {
    label: 'Installs',
    color: 'hsl(var(--chart-6))',
    formatter: (value) => value.toLocaleString(),
    yAxis: 'right',
  },
  revenue: {
    label: 'Total Revenue',
    color: 'hsl(var(--chart-1))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'left',
  },

  'revenues.moloco': {
    label: 'Moloco',
    color: 'hsl(var(--chart-5))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'left',
  },
  'revenues.facebook': {
    label: 'Facebook',
    color: 'hsl(var(--chart-6))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'left',
  },
  'revenues.admob': {
    label: 'Admob',
    color: 'hsl(var(--chart-7))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'left',
  },
  'revenues.vungle': {
    label: 'Vungle',
    color: 'hsl(var(--chart-9))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'left',
  },
  'revenues.applovin': {
    label: 'AppLovin',
    color: 'hsl(var(--chart-10))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'left',
  },
  'revenues.mintegral': {
    label: 'Mintegral',
    color: 'hsl(var(--chart-11))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'left',
  },
  'revenues.pangle': {
    label: 'Pangle',
    color: 'hsl(var(--chart-13))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'left',
  },
  'revenues.apple': {
    label: 'Apple Sub',
    color: 'hsl(var(--chart-8))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'left',
  },
  'revenues.google_play_subscription': {
    label: 'GP Sub',
    color: 'hsl(var(--chart-14))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'left',
  },
  cost: {
    label: 'Total Cost',
    color: 'hsl(var(--chart-2))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'left',
  },
  'costs.facebook': {
    label: 'Facebook',
    color: 'hsl(var(--chart-3))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'left',
  },
  'costs.ads': {
    label: 'Ads',
    color: 'hsl(var(--chart-4))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'left',
  },
  'costs.ads_30': {
    label: 'Ads 30',
    color: 'hsl(var(--chart-5))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'left',
  },
  'costs.apple_ads': {
    label: 'Apple Ads',
    color: 'hsl(var(--chart-6))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'left',
  },
  'costs.facebook.cpi': {
    label: 'facebook',
    color: 'hsl(var(--chart-7))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'right',
  },
  'costs.ads.cpi': {
    label: 'ads',
    color: 'hsl(var(--chart-8))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'right',
  },
  'costs.ads_30.cpi': {
    label: 'ads 30',
    color: 'hsl(var(--chart-9))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'right',
  },
  'costs.apple_ads.cpi': {
    label: 'apple ads',
    color: 'hsl(var(--chart-10))',
    formatter: (value) => formatUSD(value, 0, 0),
    yAxis: 'right',
  },
}

const metricGroups = {
  all: {
    label: 'All',
    metrics: ['profit', 'revenue', 'cost', 'er'],
  },
  users: {
    label: 'User Metrics',
    metrics: ['dau', 'dnu', 'installs'],
  },
  revenueDetails: {
    label: 'Revenue Details',
    metrics: [
      'revenues.moloco',
      'revenues.facebook',
      'revenues.admob',
      'revenues.vungle',
      'revenues.applovin',
      'revenues.mintegral',
      'revenues.pangle',
      'revenues.apple',
      'revenues.google_play_subscription',
    ],
  },
  costDetails: {
    label: 'Cost Details',
    metrics: ['costs.facebook', 'costs.ads', 'costs.ads_30', 'costs.apple_ads'],
  },
  costCpiDetails: {
    label: 'Cost CPI',
    metrics: [
      'costs.facebook.cpi',
      'costs.ads.cpi',
      'costs.ads_30.cpi',
      'costs.apple_ads.cpi',
    ],
  },
}

const RoiChart = ({ data }) => {
  const [selectedMetrics, setSelectedMetrics] = useState([
    'profit',
    'revenue',
    'cost',
  ])

  const [showLabel, setShowLabel] = useState(false)

  const processedData = useMemo(() => {
    return data
      .map((item) => {
        const processed = {
          ...item,
          date: String(item.date).slice(4, 8),
        }
        Object.entries(item.revenues || {}).forEach(([key, value]) => {
          processed[`revenues.${key}`] = value
        })
        Object.entries(item.costs || {}).forEach(([key, value]) => {
          processed[`costs.${key}`] = value.costs
          processed[`costs.${key}.cpi`] = value.costs / value.installs
        })
        return processed
      })
      .reverse()
  }, [data])

  return (
    <Card className="shadow-none border-none">
      <div className="flex flex-wrap gap-4 px-6">
        {Object.entries(metricGroups).map(([groupKey, group]) => (
          <div key={groupKey} className="space-y-2">
            <h3 className="text-sm font-semibold">{group.label}</h3>
            <div className="flex flex-wrap gap-4">
              {group.metrics.map((metricKey) => {
                const metric = chartConfig[metricKey]
                return (
                  <div key={metricKey} className="flex items-center space-x-2">
                    <Checkbox
                      id={metricKey}
                      checked={selectedMetrics.includes(metricKey)}
                      onCheckedChange={(checked) => {
                        setSelectedMetrics(
                          checked
                            ? [...selectedMetrics, metricKey]
                            : selectedMetrics.filter((m) => m !== metricKey),
                        )
                      }}
                    />
                    <label
                      htmlFor={metricKey}
                      className="text-sm font-medium"
                      style={{ color: metric.color }}
                    >
                      {metric.label}
                    </label>
                  </div>
                )
              })}
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center px-6">
        <MyCheckbox
          label="Show Label"
          checked={showLabel}
          onCheckedChange={setShowLabel}
        />
      </div>
      <CardContent>
        <ChartContainer
          className="aspect-auto h-[500px] w-full"
          config={chartConfig}
        >
          <LineChart
            data={processedData}
            margin={{ top: 20, right: 50, bottom: 20, left: 50 }}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="date" tickLine={false} axisLine={false} />
            <YAxis
              yAxisId="left"
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => formatUSD(value, 0, 0)}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => value.toLocaleString()}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="dot" />}
            />
            {selectedMetrics.map((metricKey) => {
              const metric = chartConfig[metricKey]
              return (
                <Line
                  key={metricKey}
                  type="monotone"
                  dataKey={metricKey}
                  stroke={metric.color}
                  yAxisId={metric.yAxis}
                  strokeWidth={2}
                  dot={{ fill: metric.color }}
                  activeDot={{ r: 6 }}
                >
                  {showLabel && (
                    <LabelList
                      position="top"
                      offset={12}
                      formatter={metric.formatter}
                    />
                  )}
                </Line>
              )
            })}
          </LineChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}

export default RoiChart
